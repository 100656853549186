$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.groupSettingsTable {
  width: 100%;
  color: #737373;
  display: block;
  max-height: 250px;
  border-collapse: separate !important;
  font-weight: 500;
  table-layout: fixed;
  overflow-y: auto;
  padding: 5px 0 !important;
  font-size: 13px;

  table {
    width: 100%;
  }

  td {
    padding: 5px !important;
    background-color: #f8f8f8;
    text-align: left;
    word-wrap: break-word;
    width: calc(100vw / 20);
    min-width: 100px;
    border-top: 0.5px solid #dadada;
    border-bottom: 0.5px solid #dadada;
    border-right: none;
    border-left: none;
    border-right: none;
  }

  th {
    padding: 5px !important;
    background-color: white;
    text-align: left;
    word-wrap: break-word;
    width: calc(100vw / 20);
    min-width: 100px;
    border-top: 0.5px solid #dadada;
    border-bottom: 0.5px solid #dadada;
    border-right: none;
    border-left: none;
    font-weight: 500;
  }

  th:first-child,
  td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0.5px solid #dadada;
  }

  th:last-child,
  td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 0.5px solid #dadada;
  }

  .tokenColumn {
    min-width: 125px !important;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .noDataRow {
    min-width: 730px !important;
    max-width: 730px;
  }

  button.editSettings {
    background-color: #f8f8f8;
    color: black;
    border: 1px solid black;
    width: 60px;
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    margin: 0 5px;
  }

  .expandOrgContainer {
    td {
      background-color: white;
    }
    .editRowContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .editRow {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        label {
          font-weight: 500 !important;
          color: #5b5a5e !important;
          font-size: 13px !important;
          color: #737373;
          width: 150px;
        }

        input.orgSettings {
          width: 400px;
          border-radius: 5px;
          border: 1px solid #f8f8f8;
          color: #737373;
        }
      }
    }
    .buttonIntegration {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      color: $tc_primary_blue;
      background-color: white;
      border: none;
      cursor: pointer;
    }
  }
}

.groupManagementTable {
  width: 100%;
  color: #737373;
  display: block;
  max-height: 550px;
  border-collapse: separate !important;
  font-weight: 500;
  table-layout: fixed;
  overflow-y: auto;
  padding: 5px 0 !important;

  th,
  td {
    padding: 5px !important;
    background-color: #f8f8f8;
    text-align: left;
    word-wrap: break-word;
    width: calc(100vw / 20);
    min-width: 100px;
    border: 0.5px solid #dadada;
    border-right: none;
    border-left: 0.5px solid #dadada;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 0.5px solid #dadada;
  }

  .groupContainer {
    min-width: 180px !important;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 1rem;
  }

  .selectGroup {
    cursor: pointer;
    font-weight: 600;
  }

  .dateColumn {
    min-width: 100px !important;
    max-width: 100px;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  align-items: center;
  height: 50px;

  .searchInput {
    margin-left: 1rem;
    width: 300px;
    font-size: 12px;
  }

  button {
    padding: 0.8rem 1.5rem !important;
    border-radius: 5px;
    font-size: 12px !important;
    color: white;
    line-height: 1 !important;
    margin-right: 5px !important;
    font-weight: 600 !important;
    background-color: #18618e;
    border: 1px solid #18618e;
  }

  button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  h2.clinicTitle {
    font-size: 16px;
    font-weight: 600;
    color: #565559;
  }
}

.paginationControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 5px;

  button {
    margin: 0 8px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #dadada;
    border-radius: 5px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  span {
    display: inline-block;
    margin: 0 8px;
    font-size: 14px;
  }

  div.paginationInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100px;
  }
}

.modalTitle,
.modalDraftTitle {
  text-align: left;

  h4 {
    color: #565559 !important;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
  }

  span {
    color: #565559 !important;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
}

.modalDraftTitle {
  h4 {
    font-size: 16px !important;
  }
}

//Override modal styles
.modal {
  @include forceFontFamily;

  :global {
    .modal-dialog {
      opacity: 1;

      .modal-content {
        width: 100%;
        padding: 0;
        box-shadow: none !important;
        border: none !important;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12) !important;
        border: 1px solid #ddd;
        border-radius: 10px !important;
      }

      .modal-header {
        padding: 5px 5px;
        border-bottom: 0;
        background: inherit;
        font-family: inherit;
        align-items: flex-start !important;
        background-color: #f8f8f8;
        border-top: 1px solid #ddd;
        border-radius: 10px !important;

        .modal-title {
          width: 100%;
          user-select: none;
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex !important;
          align-items: center !important;
          justify-content: start !important;
          line-height: 1.5;
          font-weight: 600;
          padding: 10px 20px !important;
        }

        .close {
          font-size: 20px;
          margin: 1rem !important;
        }
      }

      .modal-body {
        min-width: 44rem;
        padding: 0 15px;
        overflow-y: auto;

        .dropdown-menu {
          max-height: 320px;
        }
      }

      .modal-footer {
        border-radius: 10px !important;
        border-top: 0;
        display: block;
        text-align: right;
        background-color: white;
        padding: 18px 30px;
        margin: 0;

        &:empty {
          padding: 15px 0;
          background-color: transparent;
        }

        button {
          padding: 0.8rem 2rem !important;
          border-radius: 0.5rem !important;
          font-size: 1.6rem !important;
          line-height: 1 !important;
          margin-right: 5px !important;
          font-weight: normal !important;
        }

        > button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.backdrop:global(.modal-backdrop) {
  background-color: #eef0f5;
  opacity: 0.5;
}

.titleText {
  color: #5b5a5e !important;
  display: inline-block !important;
  font-weight: 500;
  font-size: 14px !important;
  margin-right: 0.5em !important;
}

.modalContent {
  margin: 5px;

  .groupContainer {
    margin: 5px 0px;

    h3 {
      padding: 1px 5px;
      color: #000 !important;
      font-size: 12px !important;
      font-weight: 600;
      display: block;
    }

    input {
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 0;
      height: 30px;
    }

    .groupNameInput {
      width: 300px;
    }

    .groupSettings {
      color: #000;
    }
  }
}

.modalDraftNotice {
  margin: 5px;

  .modalDraftText {
    color: #5b5a5e !important;
    font-size: 15px !important;
    font-weight: 400;

    strong {
      font-weight: 500 !important;
    }
  }
}

.groupSettingsModalContent {
  padding: 20px;
}

.groupSettingsCheckboxContainer {
  display: flex;
  gap: 20px;
}

.groupSettingsColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.groupSettingsCheckboxItem {
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    font-weight: 400 !important;
    color: #5b5a5e !important;
    font-size: 13px !important;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 15px;
  margin-top: 10px;

  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #5b5a5e !important;
  }

  button.saveSettings:disabled {
    background-color: #cbcbcc;
  }

  button.cancelSettings:disabled {
    border-color: #cbcbcc;
  }
}

button.cancelSettings {
  font-weight: 500;
  background-color: white;
  color: #18618e;
  border: 1px solid #18618e;
  width: 80px;
  height: 32px;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
}

button.saveSettings {
  background-color: #18618e;
  color: white;
  font-weight: 500;
  border: none;
  z-index: 99;
  width: 80px;
  height: 32px;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
}

.integrationIdInput {
  width: 250px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0;
  height: 30px;
}

.twoWaySyncContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hiddenElement {
  visibility: hidden;
}

.noDisplay {
  display: none;
}

.loaderInt {
  color: $tc_primary_blue;
}

.timezoneSetting {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-top: -10px;
  .optionLabel {
    padding-top: 1rem !important;
    display: flex;
    align-items: center;
    font-size: 13px;
    gap: 10px;
    font-weight: normal;
    .checkbox {
      margin: 0;
    }
  }
}
